import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import withWindowResize from '../../../ui/components/window-resize';
import { Helpers } from '../../../core/src/helpers';

import './mobile-ink-finder.less';

const SuppliesFinder = loadable(() => Helpers.retryFunc(() => import('../../../supplies-finder')));

const mobileInkFinder = ({ width }) => {
    let isMobile = width > 0 && width <= 768;
    let [renderedMobile, setRenderedMobile] = useState(false);

    /**
     * This makes sure when resizing browser between mobile/desktop several times,
     * it will only load the elicit scripts once.
     */
    useEffect(() => {
        if (isMobile && !renderedMobile) {
            setRenderedMobile(true);
        }
    }, [isMobile]);

    return (
        (isMobile || renderedMobile) && (
            <SuppliesFinder
                header="Find your ink or toner cartridges"
                mobileSubHeader="Includes FREE Shipping"
                mobileOnly
            />
        )
    );
};

export default withWindowResize(mobileInkFinder);
