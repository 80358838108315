import React from 'react';
import { Container, SkeletonLoader } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';

const homeShoppingAssistant = ({ homeShoppingAssistant }) => {
    if (!homeShoppingAssistant || homeShoppingAssistant.length < 1) return null;

    return (
        <div>
            <Container
                className={`shopping-assistant shopping-assistant-loader ${
                    homeShoppingAssistant.length === 2 ? ' twoTile' : ''
                }`}
            >
                {homeShoppingAssistant.map((h, k) => (
                    <SkeletonLoader key={`hsSkeleton-${k}`} className="content-card-banner" />
                ))}
            </Container>
        </div>
    );
};

export default withPageComponents(homeShoppingAssistant, {
    components: ['homeShoppingAssistant'],
});
