import React from 'react';
import { Container, Grid, ContentCard } from '@hpstellar/core/visId';

import withPageComponents from '../../../page/components/with-page-components';
import usePromotionImpressions from '../../../hooks/usePromotionImpressions';
import { getVisIdBannerContent } from '../../../utility/setHtml';

import './home-banner-three.less';

const promoTiles = ({ homeBannerThree = [], analyticsData }) => {
    const banners = homeBannerThree;

    usePromotionImpressions({
        data: banners,
        id: 'homeBannerThree',
        analyticsData,
        componentKey: 'homeBannerThree',
    });

    if (banners.length <= 0) {
        return null;
    }

    return (
        <div className="promoTiles">
            <Container>
                <Grid container>
                    {banners.map((b, i) => {
                        const banner = getVisIdBannerContent(b);

                        return (
                            <Grid col lg={4} md={4} sm={4} xs={12} key={i}>
                                <ContentCard
                                    buttons={banner?.buttons}
                                    description={banner?.descriptionDesktop}
                                    imageProps={{
                                        src: banner?.imageDesktopSrc,
                                        alt: banner?.imageAlt,
                                    }}
                                    subtitle={banner?.subtitleDesktop}
                                    title={banner?.titleDesktop}
                                    titleTypographyProps={{ tag: 'h2' }}
                                    CalloutProps={banner?.CalloutProps}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </div>
    );
};

export default withPageComponents(promoTiles, { components: ['homeBannerThree'], analyticsData: true });
