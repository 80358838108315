import React from 'react';
import { Container, SkeletonLoader } from '@hpstellar/core';

const secondaryHomeBanner = ({}) => {
    return (
        <div>
            <Container className={`secondaryBanner secondaryBanner-loader`}>
                <SkeletonLoader className="content-card-banner" />
                <SkeletonLoader className="content-card-banner" />
            </Container>
        </div>
    );
};

export default secondaryHomeBanner;
