import React from 'react';

import withPageComponents from '../../page/components/with-page-components';
import JsonLd from '../../shared/components/json-ld';

const EMPTY_OBJECT = {};

const homeBanner = ({ homePageSettings = EMPTY_OBJECT, proxyHost = 'https://www.hp.com', siteConfig }) => {
    const { schemaMarkup: schemaSettings } = homePageSettings;
    //remove key from component value and map type to @type
    const { key, type, ...rest } = schemaSettings || {};
    const { basename = '/us-en/shop' } = siteConfig;
    const schemaMarkup = {
        '@type': type,
        url: `${proxyHost}${basename}`,
        ...rest,
    };

    return !!type && <JsonLd schemaMarkup={schemaMarkup} />;
};

export default withPageComponents(homeBanner, { components: ['homePageSettings'], proxyHost: true, siteConfig: true });
