import React from 'react';
import { CategoryTiles } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import { Helpers } from '../../../core/src/helpers';
import { isSVG } from '../../../category-landing-page/components/shared';
import { getTileContent } from '../../../utility/setHtml';

import './home-tiles.less';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const homeBanner = ({ homeBanner = EMPTY_OBJECT, width }) => {
    const { tileLayouts = EMPTY_OBJECT } = homeBanner;
    const { tiles = EMPTY_ARRAY, variation = 'withSmallImage', orientation = 'horizontal' } = tileLayouts;

    const newTiles = tiles.map(tile => {
        const derivedTile = getTileContent(width <= 768)(tile);
        if (isSVG(derivedTile.image.icon)) {
            return {
                ...derivedTile,
                image: {
                    ...derivedTile.image,
                    icon: (
                        <span
                            className="svg-icon"
                            dangerouslySetInnerHTML={Helpers.createMarkup(derivedTile.image.icon)}
                        />
                    ),
                },
            };
        }
        return derivedTile;
    });

    if (tiles.length > 0) {
        return (
            <CategoryTiles
                tiles={newTiles}
                orientation={orientation}
                size="small"
                variation="withIcon"
                className="home-tiles"
            />
        );
    }
    return null;
};

export default withPageComponents(homeBanner, { components: ['homeBanner'], device: true });
