import React from 'react';
import { ContentCardBanner } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import { getBannerContentArray } from '../../../utility/setHtml';
export { default as ShoppingAssistantLoader } from './skeleton-loader';

import './shopping-assistant-tiles.less';

const homeShoppingAssistant = ({ homeShoppingAssistant, width }) => {
    if (!homeShoppingAssistant || homeShoppingAssistant.length < 1) return null;

    return (
        <div className={`shopping-assistant${homeShoppingAssistant.length === 2 ? ' twoTile' : ''}`}>
            <ContentCardBanner
                orientation={width > 1365 || width < 768 ? 'horizontal' : 'vertical'}
                banners={getBannerContentArray(homeShoppingAssistant, { titleTypographyProps: { tag: 'h2' } })}
                className="content-card-banner"
            />
        </div>
    );
};

export default withPageComponents(homeShoppingAssistant, {
    components: ['homeShoppingAssistant'],
    device: true,
});
