import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Carousel, ProductTile } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import useProductPrice from '../../../hooks/useProductPrice';
export { default as FeaturedProductsLoader } from './skeleton-loader';

import { getProductShowcaseProps } from '../../../product/get-product-props';
import { Helpers } from '../../../core/src/helpers';
import useProductImpressions from '../../../hooks/useProductImpressionsV2';
import withEinstein from '../../../einstein/withEinstein';
import './featured-products.less';

const getSlickSettings = screenWidth => ({
    dots: true,
    draggable: true,
    arrows: true,
    equalHeight: true,
    slidesToShow: screenWidth > 1365 ? 4 : 3,
    slidesToScroll: 1,
    className: 'featuredProductCarousel',
    infinite: true,
    showDotsCounter: true,
    responsive: [
        {
            breakpoint: 1365,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                draggable: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
            },
        },
        {
            breakpoint: 767,
            settings: {
                draggable: true,
                slidesToShow: 1.4,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 360,
            settings: {
                draggable: true,
                slidesToShow: 1.2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 300,
            settings: {
                draggable: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
});

const featuredProducts = ({ featuredProducts, einsteinProducts }) => {
    const { title = 'Featured Products' } = featuredProducts || {};
    const products =
        einsteinProducts && einsteinProducts.length > 0
            ? einsteinProducts
            : featuredProducts && featuredProducts.products;
    const { prices } = useProductPrice(products);

    const list = 'home-featured';
    const productsWithAnalytics = useProductImpressions({
        products,
        list,
        componentKey: 'featuredProducts',
    });
    const screenWidth = useSelector(state => state && state.ui && state.ui.width);
    const isCarousel = screenWidth < 1366;
    if (productsWithAnalytics && productsWithAnalytics.length > 0) {
        const productTiles = productsWithAnalytics.map((prod, idx) => {
            const { salePrice, regularPrice, locale, currency } = prices[prod.sku] || {};

            const price = salePrice
                ? {
                      regularPrice,
                      salePrice,
                      locale,
                      currency,
                      customPrefix: 'Starting at',
                      tierMsg: undefined,
                  }
                : undefined;

            return (
                <div className="featureTileContainer" key={`${prod.name}_${idx}`}>
                    <ProductTile
                        className="featured-tile"
                        {...getProductShowcaseProps(prod, price, {
                            analyticsData: { list, position: idx + 1 },
                            priceProps: {
                                size: 'large',
                            },
                            headerProps: {
                                compact: true,
                            },
                        })}
                        isDisplaySku={false}
                        ratingSpaceReserved={true}
                    />
                </div>
            );
        });
        const numTiles = productTiles && productTiles.length;
        const shouldUseCarousel = numTiles > 3 && isCarousel;
        return (
            <div className="home-featured-products center-section">
                <Typography
                    variant="display"
                    tag="h2"
                    className="home-headers"
                    dangerouslySetInnerHTML={Helpers.createMarkup(title)}
                />
                <div className={`featured-prods${!shouldUseCarousel ? ' home-flex-tiles' : ''}`}>
                    {shouldUseCarousel ? (
                        <Carousel {...getSlickSettings(screenWidth)}>{productTiles}</Carousel>
                    ) : (
                        productTiles
                    )}
                </div>
            </div>
        );
    }
    return null;
};

export default withEinstein(
    withPageComponents(featuredProducts, { components: ['featuredProducts'] }),
    'featuredProducts',
    product => ({
        ...(product || {}),
        ctaViewDetails: 'View Details',
    })
);
