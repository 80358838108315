import React from 'react';
import { ProductTileSkeleton } from '@hpstellar/core';

export default ({}) => {
    return (
        <div className="home-featured-products center-section home-featured-products-loader">
            <div className="featured-prods home-flex-tiles">
                {Array(4)
                    .fill()
                    .map(() => (
                        <ProductTileSkeleton className="tile" />
                    ))}
            </div>
        </div>
    );
};
