import React from 'react';

import { useLazyLoadSetttings } from '../../hooks/useSiteConfig';

import HeroBanner from './hero-banner';
import HomeTiles from './home-tiles';
import SecondaryHomeBanner, { SecondaryBannerLoader } from './secondary-home-banner';
import HomeShoppingAssistant, { ShoppingAssistantLoader } from './shopping-assistant-tiles';
import HomeBannerThree from './home-banner-three';
import HomePopularProducts from './home-popular-products';
import FeaturedProducts, { FeaturedProductsLoader } from './featured-products';
import HomeMobileCategoryTiles from './mobile-category-tiles';
import MobileInkFinder from './mobile-ink-finder';
import SchemaMarkup from './home-schema-markup';
import { useIsPrivateStore } from '../../hooks/useUserData';

import { Helpers } from '../../core/src/helpers';

import './home.less';

const getLazyLoadSettings = Helpers.memoize(
    (id, lazyLoadSettings, placeholder) => {
        return { ...lazyLoadSettings, placeholder };
    },
    id => {
        return id;
    },
);

const HomePage = ({ vanityUrl, templateKey }) => {
    const lazyLoadSettings = useLazyLoadSetttings(templateKey, vanityUrl);
    const isPrivateStore = useIsPrivateStore();
    return (
        <div className="home">
            <SchemaMarkup />
            <HeroBanner />
            <HomeTiles />
            <HomeMobileCategoryTiles lazyLoadSettings={lazyLoadSettings} />
            <MobileInkFinder lazyLoadSettings={lazyLoadSettings} />
            <HomeShoppingAssistant
                lazyLoadSettings={getLazyLoadSettings(
                    'homeShoppingAssistant',
                    lazyLoadSettings,
                    <ShoppingAssistantLoader />,
                )}
            />
            <SecondaryHomeBanner
                lazyLoadSettings={getLazyLoadSettings('secondaryBanner', lazyLoadSettings, <SecondaryBannerLoader />)}
            />
            <div style={isPrivateStore ? null : { minHeight: '524px' }}>
                <FeaturedProducts lazyLoadSettings={getLazyLoadSettings('featuredProducts', lazyLoadSettings)} />
            </div>
            <HomeBannerThree
                lazyLoadSettings={getLazyLoadSettings(
                    'homeBannerTHree',
                    lazyLoadSettings,
                    <div style={{ minHeight: '526px' }} />,
                )}
            />
            <div style={isPrivateStore ? null : { minHeight: '528px' }}>
                <HomePopularProducts lazyLoadSettings={getLazyLoadSettings('populareProducts', lazyLoadSettings)} />
            </div>
        </div>
    );
};

export default HomePage;
