import React from 'react';
import { HeroBanner } from '@hpstellar/core/visId';
import withPageComponents from '../../../page/components/with-page-components';
import { extractCommonProperties } from '../../../utility/setHtml';

import './hero-banner.less';

const heroBanner = ({ homeHeroBanner }) => {
    if (!homeHeroBanner) return null;
    if (homeHeroBanner && homeHeroBanner.length > 0) {
        const { slides, commonProperties } = extractCommonProperties(homeHeroBanner);
        return (
            <>
                <HeroBanner className="homeHeroBanner" slides={slides}  {...commonProperties}/>
            </>
        );
    }
    return null;
};

export default withPageComponents(heroBanner, { components: ['homeHeroBanner'] });
