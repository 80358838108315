import React from 'react';

import withPageComponents from '../../../page/components/with-page-components';
import Topseller from '../../../shared/components/topseller';

import withEinstein from '../../../einstein/withEinstein';

import './home-popular-products.less';

const homePopularProducts = ({ homePopularProducts, vanityUrl, einsteinProducts }) => {
    const products =
        einsteinProducts && einsteinProducts.length > 0
            ? einsteinProducts
            : homePopularProducts && homePopularProducts.products;
    const carouselSettings = {
        className: 'home-popular-products-carousel',
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        showDotsCounter: true,
        arrows: true,
        infinite: true,
        equalHeight: true,
        responsive: [
            {
                breakpoint: 1919,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 1365,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    draggable: true,
                    slidesToShow: 1.4,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <Topseller
            products={einsteinProducts || products}
            title={'You may also like'}
            carouselSettings={carouselSettings}
            className={'home-popular-products'}
            productTileClassName={'home-popular-card'}
            list="home-popularproduct"
            vanityUrl={vanityUrl}
            componentKey="homePopularProducts"
            linkTarget={'_self'}
            priceProps={{ size: 'large' }}
        />
    );
};

export default withEinstein(
    withPageComponents(homePopularProducts, { components: ['homePopularProducts'] }),
    'homePopularProducts'
);
