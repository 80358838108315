import React from 'react';

import {Container,Grid,IconNavigationTile} from '@hpstellar/core';

import withWindowResize from '../../../ui/components/window-resize';
import withPageComponents from '../../../page/components/with-page-components';

const HomeMobileCategoryTiles = ({ homeMobileCategoryTiles, width }) => {
    if (!(homeMobileCategoryTiles && homeMobileCategoryTiles.length > 0 && width <= 768)) return null;
    return (
        <Container>
            <Grid container>
                {homeMobileCategoryTiles.map((item, key) => {
                    return (
                        <Grid col md={4} xs={4} key={key} verticalGutter>
                            <IconNavigationTile {...item} />
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
};

export default withWindowResize(
    withPageComponents(HomeMobileCategoryTiles, { components: ['homeMobileCategoryTiles'] })
);
